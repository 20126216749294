.fedit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: white;
}


.fedit__text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  padding: 2rem;
  font-size: 1.3rem;
  line-height: 2rem;
  overflow-y: scroll;
  white-space-collapse: preserve;
}
.fedit__text_125 {
  font-size: calc(1.3rem * 1.25);
  line-height: calc(2rem * 1.25);
}
.fedit__text_150 {
  font-size: calc(1.3rem * 1.5);
  line-height: calc(2rem * 1.5);
}
.fedit__text_200 {
  font-size: calc(1.3rem * 2);
  line-height: calc(2rem * 2);
}
.fedit__text_250 {
  font-size: calc(1.3rem * 2.5);
  line-height: calc(2rem * 2.5);
}
.fedit__text_300 {
  font-size: calc(1.3rem * 3);
  line-height: calc(2rem * 3);
}
.fedit__text_400 {
  font-size: calc(1.3rem * 4);
  line-height: calc(2rem * 4);
}
.fedit__text_600 {
  font-size: calc(1.3rem * 6);
  line-height: calc(2rem * 6);
}

.fedit__cursor {
  display: inline-block;
  width: 0;
  position: relative;
  width: 2rem;
  margin-left: -1rem;
  margin-right: -1rem;
  text-align: center;
  color: rgb(86, 183, 86);
}
.fedit__text_600 .fedit__cursor{
  width: 4rem;
  margin-left: -2rem;
  margin-right: -2rem;
}
.fedit__cursor-word {
  white-space: nowrap;
}


/* controls */

.fedit__controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0,0,0,0.05);
  background-color: var(--color_b5);
  align-items: stretch;
}

.fedit__controls-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 8rem;
  height: 50%;
}

.fedit__btn {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  background-color: rgb(86, 183, 86, 0.3);
  background-color: white;
  font-size: 1.8rem;
  font-weight: 500;
  color: rgba(0,0,0,0.55);
  border-radius: 0.7rem;
  vertical-align: top;
}
.fedit__btn_func {
  background-color: #2d4e662e;
  background-color: var(--color_b1l2);
  background-color: #2d4e6612;
}
.fedit__btn_top {
  background-color: var(--color_b5); 
}
.fedit__btn_colored {
  background-color: var(--color_b1l2);
}
.fedit__btn_empty {
  opacity: 0;
  pointer-events: none;
}
.fedit__btn .material-symbols-rounded {
  font-size: inherit;
}
.fedit__btn_disabled {
  pointer-events: none;
  opacity: 0.2;
}


/* layout 1 */
.fedit_l1 {
  --btn-my: min(0.7rem, 1dvh); /* button margin y */
  --btn-mx: min(0.7rem, 1dvw); /* button margin x */
  --btn-h: min(4.5rem, 10dvh, 13dvw); /* button height */
  --controls-p: min(0.5rem, 3dvw);

  --controls-h: calc(5*(var(--btn-h) + 2*var(--btn-my)) + 2*var(--btn-my));
  --btn-w: calc((100% - 18*var(--btn-mx) - 1*var(--controls-p)) / 9);
  --btn-fs: min(calc(0.5*var(--btn-h)), 2.5rem);
  --btns-right-w: calc(var(--btn-w) + 2*var(--btn-mx) + 1*var(--controls-p));
}
.fedit_l4 {
  --btn-my: min(0.7rem, 1dvh); /* button margin y */
  --btn-mx: min(0.7rem, 1dvw); /* button margin x */
  --btn-h: min(4.5rem, 10dvh, 13dvw); /* button height */
  --controls-p: min(0.5rem, 3dvw);

  --controls-h: calc(5*(var(--btn-h) + 2*var(--btn-my)) + 2*var(--btn-my));
  --btn-w: calc((100% - 18*var(--btn-mx) - 1*var(--controls-p)) / 9);
  --btn-fs: min(calc(0.5*var(--btn-h)), 2.5rem);
  --btns-right-w: calc(var(--btn-w) + 2*var(--btn-mx) + 1*var(--controls-p));
}
.fedit_l2 {
  --btn-my: min(0.7rem, 1dvh); /* button margin y */
  --btn-mx: min(0.7rem, 1dvw); /* button margin x */
  --btn-h: min(4.5rem, 10dvh, 13dvw); /* button height */
  --controls-p: min(0.5rem, 3dvw);

  --controls-h: calc(4*(var(--btn-h) + 2*var(--btn-my)) + 2*var(--btn-my));
  --btn-w: calc((100% - 18*var(--btn-mx)) / 9);
  --btn-fs:  min(calc(0.5*var(--btn-h)), 2.5rem);
  --btns-right-w: calc(var(--btn-w) + 2*var(--btn-mx) + 1*var(--controls-p));
}
.fedit_l3 {
  --btn-my: min(0.7rem, 1dvh); /* button margin y */
  --btn-mx: min(0.7rem, 1dvw); /* button margin x */
  --btn-h: min(4.5rem, 10dvh, 13dvw); /* button height */
  --controls-p: min(0.5rem, 3dvw);

  --controls-h: calc(4*(var(--btn-h) + 2*var(--btn-my)) + 2*var(--btn-my));
  --btn-w: calc((100% - 16*var(--btn-mx)) / 8);
  --btn-fs: min(calc(0.5*var(--btn-h)), 2.5rem);
  --btns-right-w: calc(var(--btn-w) + 2*var(--btn-mx) + 1*var(--controls-p));
}

.fedit_l4 .fedit__btn {
  font-weight: 400;
}
.fedit__controls {
  height: var(--controls-h);
  padding-top: calc(0.5*var(--btn-my));
  padding-left: calc(var(--controls-p) + 0*var(--btn-mx));
  padding-right: calc(var(--controls-p) + 0*var(--btn-mx));
}
.fedit__text {
  height: calc(100% - var(--controls-h));
  width: calc(100% - var(--btns-right-w));
}
.fedit__controls-top {
  top: calc(1.5 * var(--btn-my));
  width: var(--btns-right-w);
  height: calc(100% - var(--controls-h));
  padding-right: calc(var(--controls-p));
}
.fedit__btn{
  min-width: var(--btn-w);
  max-width: var(--btn-w);
  min-height: var(--btn-h);
  max-height: var(--btn-h);
  height: var(--btn-h);
  margin: var(--btn-my) var(--btn-mx);
  font-size: var(--btn-fs);
  line-height: var(--btn-fs);
  text-align: center;
  padding-top: calc((var(--btn-h) - var(--btn-fs))*0.5);
}
.fedit__btn-wrap {
}
.fedit__btn_double {
  min-width: calc(2*var(--btn-w) + 2*var(--btn-mx));
}
.fedit__btn_triple {
  min-width: calc(3*var(--btn-w) + 4*var(--btn-mx));
}
.fedit__btn_top{
  min-width: calc(100% - 2*var(--btn-mx));
}
.fedit__btn_top.fedit__btn_double {
  min-height: calc(2*var(--btn-h) + 2*var(--btn-my));
}

.fedit__btn_lang {
  font-size: calc(var(--btn-fs) * 0.6);
}