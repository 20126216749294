.button {
  display: inline-block;
  cursor: pointer;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  border-radius: 3px;
  background-color: var(--color_b1l);
  color: var(--color_f2);
  font-size: 1.1rem;
  line-height: 1.2rem;
  font-weight: 600;
  padding: 0.9rem 2rem;
}
.button:hover {
  background-color: var(--color_b1);
}

.button_secondary {
  border: 1px solid rgba(0,0,0,0.09);
  background-color: rgba(0,0,0,0.04);
}
.button_secondary:hover {
  border: 1px solid rgba(0,0,0,0.1);
  background-color: rgba(0,0,0,0.07);
}

.button_link {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
}
.button_link span {
  vertical-align: top;
}
.button_link:hover {
  color: var(--color-link-hover);
}


.button_link2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  color: var(--color-link-hover);
  text-decoration: none;
}
.button_link2 span {
  vertical-align: top;
}
.button_link2:hover {
  text-decoration: underline;
}