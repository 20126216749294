html {
  font-size: 18px;
  line-height: 25px;
  
  --u: 18px;
  --s: 1; /* UI scale */
  --color_b1: #A6CC6B;
  --color_b1d: #93bc56;
  --color_b1l: #CAE0A6;
  --color_b1l2: #c3e0a6e8;
  --color_b2: #E4E9F3;
  --color_b3: #fee2b3;
  --color_b3l: #fee2b3;
  --color_b3l2: #FCECD4;
  --color_b4: #96ca96;
  --color_b5: #EDEEF0;
  --color_f1: #2D4E66;
  --color_f2: #333929;
  --color-link-hover: rgb(65, 114, 173);
}

@media (orientation: landscape) {
  html {
    --u: 2vh;
    --u: 2svh;
  }
}
@media (orientation: portrait) {
  html {
    --u: 2vw;
    --u: 2svw;
  }
}


body {
  margin: 0;
  font-family: "open-sans-hebrew", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* allow scroll to hide browser top */
  height: 100vh;


}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'open-sans-hebrew';
  src: url('fonts/Open Sans Hebrew Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* remove default styles */
button {
  all: unset;
  box-sizing: border-box;
}
button:focus {
  outline: revert;
}