.note_error {
  color: rgb(255, 0, 0);
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.note_message {
  color: rgb(55, 159, 47);
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.title1 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
}

.title2 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7rem;
  font-weight: 600;
  color: rgba(0,0,0,0.75);
}


.hover-move {
  -webkit-transition: -webkit-transform .2s ease,-webkit-box-shadow .2s ease;
  transition: -webkit-transform .2s ease,-webkit-box-shadow .2s ease;
  transition: transform .2s ease,box-shadow .2s ease;
  transition: transform .2s ease,box-shadow .2s ease,-webkit-transform .2s ease,-webkit-box-shadow .2s ease;
}
.hover-move:hover {
  -webkit-transform: translateY(-5px) translateZ(0);
  transform: translateY(-5px) translateZ(0);
  filter: drop-shadow(0.5rem 0.7rem 1rem #9a9fa34a);
  opacity: 1;
}

