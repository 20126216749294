/* misc */
.fhome-file {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 15rem;
  height: 4rem;
  margin: 0.5rem 1rem;
  border-radius: 0.15rem;
  --icon-width: 3rem;
  background-color: rgba(0,0,0,0.0);
}
.fhome-file__flex {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.fhome-file__icon {
  display: inline-block;
  width: var(--icon-width);
  text-align: center;
  font-size: 2rem;
  color: var(--color_f1);
  opacity: 0.8;
}
.fhome-file__name {
  display: inline-block;
  vertical-align: middle;
  overflow-y: hidden;
  text-overflow: ellipsis;
  color: rgba(0,0,0,0.85);
  max-width: calc(100% - var(--icon-width));
  padding: 0 0.5rem 0 0;
  line-height: 1.35rem;
  margin: auto 0;
  max-height: 2.7rem;
  /*
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  */
}
.fhome-file__title {
  /*
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  opacity: 0.9;
  */
}


.fhome-file_selected {
  background-color: var(--color_b1l);
}
.fhome-file_selected .fhome-file__icon {
  opacity: 1;
  color: var(--color_b1d);
}
.fhome-file_selected .fhome-file__name {
  color: rgba(0,0,0,1);
}


/* options */
.field-input.file-add-modal__text {
  font-size: 0.9rem;
  font-weight: 400;
}