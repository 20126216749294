.nav-modal {
  height: auto;
  width: 45rem;
  max-width: 95%;
}
.nav-modal .modal-body {
  text-align: center;
  padding: 2rem 1rem 4rem 1rem;
}


.nav-link {
  display: inline-block;
  min-width: 7.5rem;
  min-height: 7rem;
  text-align: center;
  padding: min(1rem, 2dvw);
  margin: 1rem;
  text-decoration: none;
  color: rgba(0,0,0,1);
  opacity: 0.75;
}


.nav-link__svg {
  display: inline-block;
  margin-bottom: 0.4rem;
  --nav-btn-w: max(4rem, 4dvw);
  min-width: var(--nav-btn-w);
  min-height: var(--nav-btn-w);
  max-width: var(--nav-btn-w);
  max-height: var(--nav-btn-w);
}
@media (max-width: 400px) {
  .nav-link {
    min-width: 6.5rem;
  }
  .nav-link__svg {
    --nav-btn-w: min(4rem, 27dvw);
  }
}
.nav-link .icon-mask::before {
  background-color: var(--color_b4);
  opacity: 0.85;
}
.nav-link_colored .icon-mask::before {
  background-color: var(--color_b3l);
}
.nav-link .icon-mask__svg {
  background-color: rgba(0,0,0,0.9);
}
.nav-link:hover .nav-link__svg .icon-mask__svg {
  color: black;
}
