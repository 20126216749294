.modal-bkg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: rgba(211, 214, 217, 0.6);
  z-index: 101;
  display: flex;
  align-items: center;
}

.modal {
  margin: auto;
  min-height: 50vh;
  width: 50rem;
  max-width: min(50rem, 94vw);
  height: auto;
  background-color: white;
  text-align: left;
  z-index: 102;
  border-radius: 0.2rem;
  overflow-y: auto;
  max-height: 100dvh;

  box-shadow: 0 16px 32px 0 rgba(10, 16, 34, .2), 0 0 0 transparent;
  --modal-p: min(4rem, 10dvw);
}
.modal-header {
  padding: calc(var(--modal-p) * 0.5) var(--modal-p) 0rem var(--modal-p);
  font-family: "Montserrat", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  border-bottom: 1px solid rgba(0,0,0,0);
  color: rgba(0,0,0,0.8);
  /* color: #6D542D; */
}
.modal-header__close {
  float: right;
  cursor: pointer;
}
.modal-header__close span {
  font-weight: 700;
}

.modal-body {
  padding: calc(var(--modal-p)*0.7) var(--modal-p);
}
.modal-toggle {
  cursor: pointer;
  display: inline-block;
}