.home {
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  --pleft: min(6rem, 10vw);
}

.home__flex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: min(10rem, 6vh, 5dvw);
  padding-bottom: 10rem;
}

.home__left {
  display: inline-block;
  max-width: 100%;
  width: 30rem;
  min-height: 25rem;
  padding-bottom: 7rem;
}
.home__right {
  display: none;
  display: inline-block;
  max-width: 23rem;
  min-width: 23rem;
  margin-right: -8rem;
  position: relative;
}
.home__polygon {
  position: absolute;
  margin: -20% 0 0 -30%;
  width: 145%;
}



/* Description */
.home__title {
  font-family: "Montserrat", sans-serif;
  font-size: 2.5rem;
  line-height: 3.2rem;
  font-weight: 700;
  color: rgba(0,0,0,0.7);
  width: 25rem;
  max-width: 100%;
  margin-bottom: 1.2rem;
  margin-top: 1rem;
}
.home__descr {
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(0,0,0,0.8);
  margin-bottom: 1.7rem;
  max-width: 21rem;
}

.button.home__btn-login {
  background-color: var(--color_b1);
  color: var(--color_f2);
  padding: 0.9rem 3.5rem;
}
.button.home__btn-login:hover {
  background-color: var(--color_b1d);
  background-color: var(--color_b1l);
  color: rgba(0,0,0,0.9); 
}
.home__btn-view {
  min-width: 10rem;
}




/* menu */
.home__links {
  margin: -1rem;
  margin-top: 2rem;
  position: relative;
}
@media (orientation: portrait) {
  .home__right {
    padding-left: 3rem;
  }
}

.home-links__link {
  position: relative;
  display: inline-block;
  min-width: 8rem;
  min-height: 7rem;
  text-align: center;
  padding: 0.5rem;
  margin: 1rem;
  text-decoration: none;
  color: rgba(0,0,0,1);
}
.home-link__text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
}



.home-links__svg {
  width: 4.2rem;
  height: 4.2rem;
  display: inline-block;
  margin-bottom: 0.3rem;
}
.home-links__svg::before {
  background-color: white;
  opacity: 1;
}
.home-links__link_colored .home-links__svg::before {
  background-color: var(--color_b3);
  opacity: 0.9;
}
.home-links__svg .icon-mask__svg {
  background-color: rgba(0,0,0,0.85);
  opacity: 0.8;
}



.home-links__link.hover-move:hover {
  filter: drop-shadow(.5rem .7rem 1rem #a1b1c462);
}
.home-links__link:hover .home-links__svg::before {
  opacity: 1;
}
.home-links__link:hover .home-links__svg {
  opacity: 1;
}
.home-links__link:hover .home-link__text {
  opacity: 1;
}


.home .page-simple__topbar {
  
}