
.fview {
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  --ptop: 1.5rem; /* padding top */
  --fontsize: 1rem;
}
.fview_100 {
  --fontsize: 1rem;
}
.fview_125 {
  --fontsize: 1.25rem;
}
.fview_150 {
  --fontsize: 1.5rem;
}
.fview_200 {
  --fontsize: 2rem;
}


.fview__file {
  position: absolute;
  width: calc(100vw - var(--rwidth));
  height: calc(100%);
  background-color: white;
  padding: 1rem 2rem;
  overflow-y: auto;
}
@media screen and (min-width: 700px) {
  .fview__file {
    padding: calc(2* var(--u)) calc(4* var(--u));
    padding: 2rem 3rem 2rem 4rem;
  }
}


/* file text */
.fview__filename {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.fview__p_title {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
  font-weight: 600;
  color: rgba(0,0,0,0.7);
  font-size: calc(var(--fontsize) * 1.7);
  line-height: calc(var(--fontsize) * 1.7 * 1.5);
}
.fview__body {
  padding-bottom: 30vh;
  color: rgba(0,0,0,0.8);
  font-size: calc(var(--fontsize));
  line-height: calc(var(--fontsize) * 1.8);
  /* white-space-collapse: preserve; */ 
}

.fview__p {
  position: relative;
  padding: 0 0.4rem;
  border-radius: 1px;
}
.fview__p_selected {
  background-color: rgb(166, 204, 107, 0.4);
  color: rgb(24, 30, 13);
}
.fview__s_selected {
  background-color: rgb(166, 204, 107, 0.6);
  color: rgb(24, 30, 13);
}
.fview__w_selected {
  background-color: rgb(166, 204, 107, 0.8);
  color: rgb(24, 30, 13);
}


/* select paragraph button */
.fview__select-p-btn {
  position: absolute;
  --p-left: min(2rem, 3vw);
  left: calc(-1 * min(1.9rem, 6vw));
  cursor: pointer;
  padding: 0.5rem;
  margin-top: -0.3rem;
  margin-left: -0.5rem;
  border-radius: 1rem;
}
.fview__select-p-btn_selected {

}
.fview__select-p-btn .icon{
  display: inline-block;
  position: relative;
  font-size: 1.3rem;
  color: var(--color_b2);
  border-radius: 100%;
}
.fview__select-p-btn_selected .icon {
  color: var(--color_b1l);
}
.fview__select-p-btn:hover .icon {
  color: var(--color_b1l);
}


.fview__editcontent {
  border: 1px solid #ccc;
  min-height: 10rem;
  padding: 1rem;
}

/* textbook */
.fview__p__answer {
  border: 1px solid rgba(0,0,0,0.1);
  padding: 0.1rem 1rem;
  margin: 0.3rem 0 2rem min(4rem, 4vw);
  border-radius: 0.2rem;
}

.fview__btn-select__text {
  position: relative;
  font-size: min(1rem, 3dvh, 4dvw);
  line-height: min(1.2rem, 3.5dvh, 4dvw);
  color: var(--color_f1);
  font-weight: 600;
  padding-top: min(0.8rem, 2dvh);
}
