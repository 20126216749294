.modal-login .modal-body {
  padding-bottom: 1rem;
  min-height: 37rem;
  padding-top: 1rem;
}
.login {
  position: relative;
  width: 20rem;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.login__title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
  color: rgba(0,0,0,0.65);
}


.login__response {
  margin-bottom: 0.7rem;
}

.login__login-btn {
  margin-bottom: 2rem;
  margin-top: 0.8rem;
  width: 100%;
}

.login__mode-btn {
  color: var(--color-link-hover);
}
.login__mode-btn:hover {
  text-decoration: underline;
}
