.form-group {
  margin-bottom: 1.6rem;
  max-width: 32rem;
}
.form-group__title {
  font-size: 1.15rem;
  margin-bottom: 0.8rem;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  font-family: "Montserrat", sans-serif;
}
.form-group__narrate {
  display: inline-block;
  cursor: pointer;
  width: 2.2rem;
}
.form-group__narrate .icon {
  background-color: var(--color_b1l);
  color: rgba(0,0,0,0.85);
  border-radius: 100%;
  position: relative;
  top: 0.3rem;
  opacity: 0.8;
}


.form-group__options {
  padding-left: 2.2rem;
}
.form-button {
  display: inline-block;
  cursor: pointer;
  padding: 1rem;
  border: 1px solid rgba(0,0,0,0.09);
  background-color: rgba(0,0,0,0.04);
  border-radius: 0.15rem;
  text-align: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
  min-width: 6rem;
}
.form-button_selected {
  border: 1px solid rgba(0,0,0,0.1);
  border: 1px solid #b3bccdab;

  background-color: var(--color_b2);
}



/* form field */
.field-label {
  color: rgba(0,0,0,0.95);
}
.field-input {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  outline: none;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 0.3rem;
  padding: 0.8rem 1rem;
  border-radius: 0.2rem;
  border: 1px solid rgba(118, 151, 185, 0.6);
  color: rgba(0,0,0,0.9);
}
.field-input:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.form-field {
  position: relative;
}
.form-field__visibility {
  position: absolute;
  top: 2.6rem;
  right: 0.8rem;
  cursor: pointer;
  color: rgb(117, 147, 177);
  font-size: 1.5rem;
}
.form-field__visibility .icon {
  font-size: inherit;
}

.field {
  position: relative;
}
.field_w-editor {
  position: unset;
}
.field_w-editor .field-input {
  width: calc(100% - 3rem);
}
.field-input__editbtn {
  float: right;
  cursor: pointer;
  margin-top: 0.8rem;
  text-align: center;
  opacity: 0.8;
}
.field-input__editbtn .icon {
  background-color: var(--color_b1l);
  color: rgba(0,0,0,0.85);
  border-radius: 100%;
  padding: 0.2rem 0 0.2rem 0;
  width: 2.1rem;
  font-size: 1.7rem;
}