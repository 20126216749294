.page-loading {
  width: 100dvw;
  height: 100dvh;
  position: relative;
}

.loading-spinner {
  position: relative;
  top: calc(50vh - 1.5rem);
  margin: 0 auto;
  border: 0.3rem solid var(--color_b1); /* Light grey */
  border-top: 0.3rem solid white; /* Blue */
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}